import Vue from 'vue'
import axios from 'axios'
// import qs from 'querystring'
import {cloneVar, apiCall} from '../../common/utils'
import io from 'socket.io-client';

// import createPersistedState from "vuex-persistedstate";

const _user = {
  user_no    : null,
  user_id    : null,
  name       : null,
  dept_name  : null,
  role       : null,
  email      : null,
  phone_no   : null,
  mobile_no  : null,
  accessToken :null,
};
const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isAuth: false,
  accessToken: null,
  user: {
    user_no    : null,
    user_id    : null,
    name       : null,
    dept_name  : null,
    role       : null,
    email      : null,
    phone_no   : null,
    mobile_no  : null,
    accessToken: null,
  }
};

const protocol = window.location.protocol;
const host = window.location.hostname;
let port = (window.location.port)? ":"+window.location.port:'';
const wlPort = window.location.port;
if(protocol==="https:"){
  port = "443"
}else{
  port = (wlPort==='8888'|| wlPort==='8080')? "8080":"";
}

const hostUrl = `${protocol}//${host}:${port}`;

// console.log( "########## hostUrl ------------------------> ", hostUrl);

let socket = null;


const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  get (state, key) {
    return state[key];
  },
  LOGIN (state, user) {
    // console.log( "**********************  store-mutation-LOGIN  **********************");
    localStorage.user = JSON.stringify(user);
    state.isAuth = true;
    $user = user;
    $isAuthed = true;
    axios.defaults.headers.common['Authorization'] = user.accessToken;
    socket = io( hostUrl,
        {
          reconnection: true,
          reconnectionAttempts:10,
          reconnectionDelay: 3000
        });

    Vue.prototype.$socket = socket;
    // console.log( "LOGIN().accessToken: " + user.accessToken );
  },
  LOGOUT (state) {
    // console.log( "**********************  store-mutation-LOGOUT **********************");
    state.accessToken = null;
    state.isAuth = false;
    state.user = cloneVar( _user );
    $user = cloneVar( _user );
    $isAuthed = false;
    console.log( "mutation ---- LOGOUT -----> $socket: ", socket );
    if(socket && socket.connected ){
      socket.disconnect();
      console.log( "mutation ---- LOGOUT -----> $socket.connected?: ", socket.connected );
    }
    axios.defaults.headers.common['Authorization'] = undefined;
    delete localStorage.user;
  },

};

const actions = {
  async LOGIN ({commit}, {userId, userPwd }) {
    // console.log( "*** ------------------- store----action-----LOGIN -------------------- ***");

    const param = {
      userId: userId,
      userPwd: userPwd,
      grant_type: 'password'
    };

    // const encodedParam = qs.stringify( param );
    // const serverUrl = $baseURL;

    try{

      const rs = await apiCall("POST", `/admin/auth/login`, param );

      if (!rs.result)
        throw new Error(rs.message);

      if (!rs.result.accessToken)
        throw new Error("no Access Token");

      axios.defaults.headers.common['Authorization'] = rs.result.accessToken;
      const r = await apiCall( 'GET', `/admin/auth/me`);

      if( !r.result ) new Error("cannot get user info");
      console.log( 'LOGIN--------- : ', r );
      $user = r.result;
      $user.accessToken = rs.result.accessToken;
      const userRole = $user.role;

      if( userRole==='ROLE_ADMIN'){
        $user.userClass = 1;
      }else if(userRole==='ROLE_MANAGER'){
        $user.userClass = 2;
      }else if(userRole==='ROLE_USER'){
        $user.userClass = 3;
      }else{
        throw new Error( 'unknown user role' );
      }
      // console.log( 'LOGIN----$user---------->', $user );
      commit('LOGIN', $user);
      return true;

    }catch(err){
      // console.log("store.LOGIN error ===============>  error: ", err );
      commit('LOGOUT');
      throw err;
    }

  },

  LOGOUT ({commit}) {
    // console.log( "*** ------------------- store ---- action ---- LOGOUT -------------------- ***");
    commit('LOGOUT');
  },

  async setLoginState({commit}, user){
    try{
      axios.defaults.headers.common['Authorization'] = user.accessToken;
      const r = await apiCall( 'GET', `/admin/auth/me`);
      // console.log( "setLoginState------------------ axios get user : ", r );
      if( r.code===200 ){
        commit('LOGIN', user);
        return true;
      } else {
        commit('LOGOUT');
        return false;
      }
    }catch(err){
      // console.log( "setLoginState ------------> ", err.message );
      commit('LOGOUT');
      throw err;
    }
  }
};

export default {
  state,
  mutations,
  actions,
}