import Vue from 'vue'
import axios from 'axios'
// import qs from 'querystring'
import {cloneVar, apiCall} from '../../common/utils'
import io from 'socket.io-client';

// import createPersistedState from "vuex-persistedstate";

const _user = {
  userNo      : null,
  userId      : null,
  name        : null,
  role        : null,
  email       : null,
  hpNo        : null,
  regDts      : null,
  userClass   : null,
  nick        : null,
};

const state = {
  isAuth       : false,
  accessToken  : null,
  user: {
    userNo     : null,
    userId     : null,
    name       : null,
    role       : null,
    email      : null,
    hpNo       : null,
    regDts     : null,
    userClass  : null,
    nick       : null,
  }
};

const protocol = window.location.protocol;
const host = window.location.hostname;
let port = (window.location.port)? ":"+window.location.port:'';
const wlPort = window.location.port;
if(protocol==="https:"){
  port = "443"
}else{
  port = (wlPort==='8888'|| wlPort==='8080')? "8080":"";
}

const hostUrl = `${protocol}//${host}:${port}`;

// console.log( "########## hostUrl ------------------------> ", hostUrl);

let socket = null;

/**
 * state를 설정하는 메서드 [store 내부에서만 사용]
 * @param {String} accessToken 
 */
const setStateUser = async (accessToken) => {
  try {
    axios.defaults.headers.common['Authorization'] = accessToken;
    const r = await apiCall( 'GET', `/user/auth/me` );

    // console.log("r.result", r.result);
  
    if( !r.result ) new Error("cannot get user info");

    $H_isAuthed = true;
    state.isAuth = true;
    state.user = r.result;
    state.accessToken = accessToken;

    // console.info( " state.accessToken =======================> ", state.accessToken);
    const userRole = state.user.role;
  
    if( userRole==='A'){ //ROLE_ADMIN
      state.user.userClass = 1;
    }else if(userRole==='M'){ //ROLE_MANAGER
      state.user.userClass = 2;
    }else if(userRole==='U'){ //ROLE_USER
      state.user.userClass = 3;
    }else if(userRole==='P'){ //ROLE_PROVIDER
      state.user.userClass = 4;
    }else{
      throw new Error( 'unknown user role' );
    }
    // console.log( '[1] setStateUser---state---------->', state );
  } catch (error) {
    // console.error( 'setStateUser---state---------->', state );
    throw error;
  }
}

// =======================================================================================================================

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  get (state, key) {
    return state[key];
  },

  async LOGIN (state, token) {
    // console.log( "**********************  store-mutation-LOGIN  **********************");

    await setStateUser(token);
    axios.defaults.headers.common['Authorization'] = token;

    socket = io( hostUrl,
        {
          reconnection: true,
          reconnectionAttempts:10,
          reconnectionDelay: 3000
        });

    Vue.prototype.$socket = socket;
    // console.log( "LOGIN().accessToken: " + user.accessToken );
  },
  LOGOUT (state) {
    // console.log( "**********************  store-mutation-LOGOUT **********************");
    state.accessToken = null;
    state.isAuth = false;
    state.user = cloneVar( _user );
    $H_user = cloneVar( _user );
    $H_isAuthed = false;
    // console.log( "mutation ---- LOGOUT -----> $socket: ", socket );
    if(socket && socket.connected ){
      socket.disconnect();
      // console.log( "mutation ---- LOGOUT -----> $socket.connected?: ", socket.connected );
    }
    axios.defaults.headers.common['Authorization'] = undefined;
    delete localStorage.accessToken;
  },

  async REFLASH (state, token) {
    await setStateUser(token);
    axios.defaults.headers.common['Authorization'] = token;
  },

};

const actions = {
  async LOGIN ({commit}, {userId, userPwd }) {
    // console.log( "*** ------------------- store----action-----LOGIN -------------------- ***");

    const param = {
      userId: userId,
      userPwd: userPwd,
      grant_type: 'password'
    };

    try{
      const rs = await apiCall("POST", `/user/auth/login`, param );
      // console.log("store rs ============> ", rs);
      if (rs.code === 401)
        return rs.result;
      if (!rs.result)
        throw new Error(rs.message);
      if (!rs.result.accessToken)
        throw new Error("no Access Token");

      axios.defaults.headers.common['Authorization'] = rs.result.accessToken;
      localStorage.accessToken = rs.result.accessToken;

      commit('LOGIN', rs.result.accessToken);
      return true;

    }catch(err){
      // console.log("store.LOGIN error ===============>  error: ", err );
      commit('LOGOUT');
      throw err;
    }
  },

  LOGOUT ({commit}) {
    // console.log( "*** ------------------- store ---- action ---- LOGOUT -------------------- ***");
    commit('LOGOUT');
  },

  async setLoginState({commit}, token){
    try{
      axios.defaults.headers.common['Authorization'] = token;
      const r = await apiCall( 'GET', `/user/auth/me`);
      if( r.code===200 ){
        commit('LOGIN', token);
        return true;
      } else {
        commit('LOGOUT');
        return false;
      }
    }catch(err){
      // console.log( "setLoginState ------------> ", err.message );
      commit('LOGOUT');
      throw err;
    }
  },

  async REFLASH({commit}, token){
    try{
      axios.defaults.headers.common['Authorization'] = token;
      const r = await apiCall( 'GET', `/user/auth/me`);
      if( r.code===200 ){
        await commit('REFLASH', token);
        return true;
      } else {
        await commit('LOGOUT');
        return false;
      }
    }catch(err){
      commit('LOGOUT');
      throw err;
    }
  },

  async authPassword ({commit}, {userId, userPwd}) {
    try {
      const param = {
        userId: userId,
        userPwd: userPwd,
        grant_type: 'password',
      }

      axios.defaults.headers.common['Authorization'] = localStorage.accessToken;
      const rs = await apiCall('POST', `/user/auth/password`, param);

      if (rs.result) return true;
      return false;

    } catch (error) {
      console.log( "authPassword fail --------->", error );
      return false;
    }
  },

};

/** [사용안함]
 * getters 사용 시 사용할 vue 폼에서
 * import { mapGetters } from 'vuex';
 * 선언 하고, ...mapGetters는 computed에서 선언
 * ...mapGetters('userStore',['isAuth', 'accessToken']);
 * method 에서
 * this.accessToken 로 호출
 */
const getters = {
  isAuth: state => state.isAuth,
  accessToken: state => state.accessToken,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}