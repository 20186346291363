import axios from 'axios'
import io from 'socket.io-client';
import moment from "moment";
import store from '@/store';
import momentKor from "moment/moment";

const protocol = window.location.protocol; // 현재 프로토콜
const host = window.location.hostname; // 현재 접속 호스트
const wlPort = window.location.port; // 현재 접속 포트
let port = "80";
if(protocol==="https:"){
  port = "443"
}else{
  port = (wlPort==='8888'|| wlPort==='8080')? "8080":"";
}

const apiServer = `${protocol}//${host}:${port}`;
// const apiServer = `https://office.xcost.me:443`;

const webServer = `${protocol}//${window.location.host}`;

const axiosConfig = {
  timeout: 0,
  responseType: "json"
}; // request time out 10 minutes;

export function connectSocketServer(){

  this.$socket = io( apiServer,
      {
        reconnection: true,
        reconnectionAttempts:10,
        reconnectionDelay: 3000
      });
}

export function sleep(ms){
  return new Promise((r) => setTimeout(r, ms));
}


/**
 * apiServer Url
 * @returns {Stirng} apiServer
 */
export const apiServerUrl = () => {
  // console.log('this.apiServer', apiServer);
  return `${protocol}//${host}:${port}`;
}


const audioContext = new AudioContext(); // for beep sound

export function beep(duration, frequency, volume){
  return new Promise((resolve, reject) => {
    // Set default duration if not provided
    duration = duration || 110;
    frequency = frequency || 440;
    volume = volume || 100;

    try{
      let oscillatorNode = audioContext.createOscillator();
      let gainNode = audioContext.createGain();
      oscillatorNode.connect(gainNode);
      // Set the oscillator frequency in hertz
      oscillatorNode.frequency.value = frequency;
      // Set the type of oscillator
      oscillatorNode.type= "square";
      gainNode.connect(audioContext.destination);
      // Set the gain to the volume
      gainNode.gain.value = volume * 0.01;
      // Start audio with the desired duration
      oscillatorNode.start(audioContext.currentTime);
      oscillatorNode.stop(audioContext.currentTime + duration * 0.001);
      // Resolve the promise when the sound is finished
      oscillatorNode.onended = () => {
        resolve();
      };
    }catch(error){
      reject(error);
    }
  });
}

export async function audioEnable(){
  try{
    await audioContext.resume();
    await window.speechSynthesis.resume();
  }catch(err){
    console.log(err);
  }
}

export async function beepSound(type=null){
  // console.log( "--------------------beepSound--------------------");
  if(!store.state.soundOn) return;
  switch(type){
    case 'tick': // timer
      await beep(20, 4200, 15);
      await beep(20, 3600, 5);
      break;
    case 'ok':
      await beep(30, 2800);
      await beep(30, 3500);
      break;
    case 'info': // info
      await beep(10, 3520, 60);
      await beep(10, 3920, 40);
      break;
    case 'primary': // message, info
      await beep(30, 2800, 50);
      await beep(20, 2000, 50);
      break;
    case 'message': // Event
      await beep(20, 1048, 50);
      await beep(30, 1176, 60);
      await beep(40, 1320, 80);
      await beep(60, 1400, 90);
      break;
    case 'success':
      await beep(25, 2000, 60);
      await beep(15, 1120, 60);
      break;
    case 'warning': // warning
      await beep(20, 2400, 60);
      await beep(20, 3220, 40);
      break;
    case 'danger': // danger
      await beep(30, 3520, 100);
      await beep(30, 2700, 80);
      await beep(30, 2000, 60);
      break;

    default:
      await beep(20, 3520, 20);
      await beep(20, 4200, 10);
      break;
  }
}


export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}


export function cloneVar( obj ){
  return JSON.parse(JSON.stringify(obj));
}

export function getQueryString( obj ){
  if(!obj) return '';
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

export function warpSite(path='main') {
  // window.location.href = `${webServer}/#/${path}`;
  console.log(`warpSite=======> ${webServer}/#/${path}`);
  return `${webServer}/#/${path}`;
}

/**
 * parseError : 각 에러코드에 따른 페이지 이동
 * @param err
 */
const parseError = (err) => {
  const code = err.code+'';
  console.log( "parseErr: ", err);

  return;
};

/**
 * API를 호출할 서버 HOST를 가져온다.
 * @param str
 * @returns {*}
 */
const getHostURL = (str='local') => {
  //console.log( "getHostURL ---- ", apiServer);
  return apiServer;
};

/**
 * 토큰만료 체크 (Alert 중복출력 방지)
 */
let tokenExpired = false;

/**
 * apiCall : REST METHOD call with resources and parameters
 * @param {String} method : GET|POST|PUT|DELETE|PATCH
 * @param {String} resource : resource uri
 * @param {Object} param : json objects default null
 * @returns {Promise<null>}
 */
export const apiCall = async (method="GET", resource="/",  param=null, accessToken=null) => {

  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = accessToken;
  }

  let response = null, ret = null;
  let url = apiServer + resource;
  const m = method.toUpperCase();
  axiosConfig['responseType'] = 'json';
  try{
    // console.log( `\t\t\t##### axios.${method} ----------> url : ${url} ----> param: ${JSON.stringify(param)}`);
    if( m==='GET'){
      response = await axios.get( url, {params: param} );
      ret = response.data;
    }else if( m==='POST'){
      response = await axios.post( url, param, axiosConfig );
      ret = response.data;
    }else if( m==='PUT'){
      response = await axios.put( url, param, axiosConfig );
      ret = response.data;
    }else if( m==='DELETE' || m==='DEL'){
      response = await axios.delete( url, {params: param} );
      ret = response.data;
    }else if( m==='PATCH'){
      response = await axios.patch( url, param, axiosConfig );
      ret = response.data;
    }else if( m==='IMAGE') {
      response = await axios.get( url, param );
      ret = response;
    }else if( m==='DOWNLOAD') {
      axiosConfig['responseType'] = 'blob';
      response = await axios.post( url, param, axiosConfig );
      ret = response;
    }else if( m==='PDF') {
      axiosConfig['responseType'] = 'application/pdf';
      response = await axios.get( url, param );
      ret = response;
    }else{
      throw new Error('Unsupported Method : ' + m );
    }
    // console.log( `\t\t\t##### axios.${method}::${url} ----> response :`,response);
    return ret;
  }catch(err){
    console.log( "###### util.apiCall------------- catch err ---> ", err );
    if( err.response ) {
      ret = err.response.data;
      console.log("###### util.apiCall------------- err.response.data ---> ", JSON.stringify(ret));
      
      if (ret.code === 11403 && !tokenExpired) {
        // 인증토큰이 만료되어 로그인이 필요합니다.
        alert("로그인 시간이 만료되었습니다. 다시 로그인 해주세요.");
        
        if (window.location.hash.includes("/admin-x/")) {
          $isAuthed = false;
          store.dispatch('adminStore/LOGOUT');
          window.location.href = `${webServer}/#/admin-x/login`;
        } else {
          store.dispatch('userStore/LOGOUT');
          window.location.href = `${webServer}/#/service/login`;
        }
        tokenExpired = true;
      }
    }
    // throw err;
  }finally{
    // eslint-disable-next-line no-unsafe-finally
    return ret;
  }
};

export const apiMultiPart = async (resource="/",  formData, accessToken=null ) => {

  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = accessToken;
  }

  let response = null, ret = null;
  let url = apiServer + resource;

  const config = {
    headers: { 'Content-Type': 'multipart/form-data'}
  };

  console.log( `\t\t##################### apiMultiPart()
  \t\t------------> method: POST
  \t\t------------> resource: ${url}
  \t\t------------> formData: --- `);

  try{
    response = await axios.post( url, formData, config );
    // console.log( response );
    ret = response.data;
  }catch(err){
    console.log( "apiMultiPart-------------ERR ---> ", err );
    ret = err.response.data;
    // alert( err.message);

  }finally{
    // eslint-disable-next-line no-unsafe-finally
    return ret;
  }
};


/**
 * 이미지 (가로x세로) 고정 리사이징
 * @param {*} file input으로 삽입한 이미지
 * @param {number} mWidth 
 * @param {number} mHeight 
 * @returns {object} {resizedImage: '', resizedBlob: ''}
 */
export const resizeImageFix = async (file, mWidth, mHeight) => {
  let resizedImage;

  let result = {
    name: file.name,
    type: file.type
  };

  const reader = new FileReader();

  reader.onload = (e) => {
    const img = new Image();
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 이미지 (가로x세로) 설정 고정
      canvas.width = mWidth;
      canvas.height = mHeight;

      ctx.drawImage(img, 0, 0, mWidth, mHeight);
      
      result.resizedImageData = canvas.toDataURL(file.type);

      canvas.toBlob((blob) => {
        // console.log('Blob created:', blob);
        result.resizedBlob = blob;
      }, file.type);
    };
    
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);

  return result;
}

/**
 * 이미지 (가로x세로) 자동 리사이징
 * @param {*} file input으로 삽입한 이미지
 * @param {number} mWidth 
 * @param {number} mHeight 
 * @returns {object} {resizedImage: '', resizedBlob: ''}
 */
export const resizeImageAuto = async (file, mWidth, mHeight) => {
  let resizedImage;

  let result = {
    name: file.name,
    type: file.type
  };

  const reader = new FileReader();

  reader.onload = (e) => {
    const img = new Image();
    
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 이미지 (가로x세로) 설정
      const maxWidth = mWidth;
      const maxHeight = mHeight;

      let width = img.width;
      let height = img.height;

      // 기존 이미지의 (가로x세로)비율을 유지하기 위해 계산
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);
      
      result.resizedImageData = canvas.toDataURL(file.type);

      canvas.toBlob((blob) => {
        result.resizedBlob = blob;
      }, file.type);
    };
    
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);

  return result;
}



const regExp = {
  addComma: /(^[+-]?\d+)(\d{3})/, //정규식
  onlyNum: /[^0-9]/g, // 숫자만 추출, 나머지 제거
}
export const commify = (n) => {
  n += '';                          // 숫자를 문자열로 변환
  while (regExp.addComma.test(n)) {
    n = n.replace(regExp.addComma, '$1' + ',' + '$2');
  }
  return n;
};

export const numberfy = (n) => {
  if(!n) return 0;
  n = n+'';
  return Number(n.replace(regExp.onlyNum, ""));
};

export const humanSize = (bytes, si=true) => {
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
    : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
};

export const humanTime = ( temp ) => {
// TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  // temp --> unit second...
  // var temp = Math.floor(milliseconds / 1000);

  var years = Math.floor(temp / 31536000);
  if (years) {
    return years + ' 년';
  }
  //TODO: Months! Maybe weeks?
  var days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
    return days + ' 일';
  }
  var hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
    return hours + ' 시간';
  }
  var minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
    return minutes + ' 분';
  }
  var seconds = temp % 60;
  if (seconds) {
    return seconds + ' 초';
  }
  return '1초 미만'; //'just now' //or other string you like;
};



/**
 * Modal alert with callback
 * @param ctx : this.$bvModal
 * @param msg : {title},{text},{level}
 * @param callback
 */
export const alertOk = (ctx, msg, callback) => {
  ctx.msgBoxOk( msg , {
    title: "Notice" ,
    buttonSize: 'sm',
    headerBgVariant: 'warning',
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
    okVariant: 'primary',
    centered: true,
    size: 'sm'
  }).then((value)=>{
    callback( value );
  }).catch(err=>{
    callback( false );
    // console.log( 'alertBox---------->'+ err.message );
  });
};

/**
 * Modal alert with callback
 * @param ctx : this.$bvModal
 * @param msg : {title},{text},{level}
 * @param callback
 */
export const alertFail = (ctx, msg, callback) => {
  ctx.msgBoxOk( msg , {
    title: "Warning" ,
    buttonSize: 'sm',
    headerBgVariant: 'danger',
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
    okVariant: 'danger',
    centered: true,
    size: 'sm'
  }).then((value)=>{
    callback( value );
  }).catch(err=>{
    callback( false );
    // console.log( 'alertBox---------->'+ err.message );
  });
};





/**
 * Modal alert with callback
 * @param ctx : this.$bvModal
 * @param msg : {title},{text},{level}
 * @param callback
 */
export const alertModal = (ctx, msg, callback) => {
  let variant = 'success';
  if( msg.level ){
    switch(msg.level){
      case 'info': variant = 'primary'; break;
      case 'warn': variant = 'warning'; break;
      case 'err': variant = 'danger'; break;
      case 'error': variant = 'danger'; break;
      case 'danger': variant = 'danger'; break;
      case 'primary': variant = 'primary'; break;
      case 'normal': variant = 'info'; break;
      default: variant= 'info'; break;
    }
  }
  if( !msg.title ) msg['title'] = '[경고] Warning ';

  // console.log( "utils.alertModal() -------------->", msg );
  ctx.msgBoxOk( msg.text, {
    title: msg.title,
    buttonSize: 'sm',
    headerBgVariant: variant,
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
    okVariant: 'primary',
    centered: true, size: 'sm'
  }).then( value => {
    callback( value );
  }).catch( err => {
    console.log( 'utils.alertModal() ------------->', err.message );
  });
};

/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const alertSync = async (ctx, msg) => {
  let variant = 'warning', r = null;
  if( msg.level ){
    switch(msg.level){
      case 'info': variant = 'primary'; break;
      case 'warn': variant = 'warning'; break;
      case 'err': variant = 'danger'; break;
      case 'error': variant = 'danger'; break;
      case 'danger': variant = 'danger'; break;
      case 'primary': variant = 'primary'; break;
      case 'normal': variant = 'info'; break;
      default: variant= 'warning'; break;
    }
  }
  if( !msg.title ) msg['title'] = '[경고] Warning ';

  try{
    r = await ctx.msgBoxOk( msg.text, {
      title: msg.title,
      buttonSize: 'sm',
      headerBgVariant: variant,
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'primary',
      centered: true, size: 'sm'
    });
  }catch(err){
    console.log("# alertAsync Error ---> ",err);
  }finally{
    return r;
  }

};

/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const alertWarn = async (ctx, msg, title=null) => {
  let r = null;
  try{
    r = await ctx.msgBoxOk( msg, {
      title: title? title:'[알림] Warning' ,
      headerBgVariant: 'warning',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'warning',
      centered: true, size: 'sm'
    });
  }catch(err){
    console.log("# alertWarn Error ---> ",err);
  }finally{
    return r;
  }
};


/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const alertPageMove = async (ctx, msg, title=null, url=webServer) => {
  let r = null;
  try{
    r = await ctx.msgBoxOk( msg, {
      title: title? title:'[알림] 성공' ,
      headerBgVariant: 'success',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'success',
      centered: true, size: 'sm'
    }).then(end => {
      window.location.href = url;
    });
  }catch(err){
    console.log("# alertWarn Error ---> ",err);
  }finally{
    return r;
  }
};


/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const warningAlertPageMove = async (ctx, msg, title=null, url='http://xcost.me') => {
  let r = null;
  try{
    r = await ctx.msgBoxOk( msg, {
      title: title? title:'[알림] 경고' ,
      headerBgVariant: 'warning',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'warning',
      centered: true, size: 'sm'
    }).then(end => {
      window.location.href = url;
    });
  }catch(err){
    console.log("# alertWarn Error ---> ",err);
  }finally{
    return r;
  }
};


/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const alertSuccess = async (ctx, msg, title=null) => {
  let r = null;
  try{
    r = await ctx.msgBoxOk( msg, {
      title: (title)? title:'[알림] 완료' ,
      headerBgVariant: 'success',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'info',
      centered: true,
      size: 'sm'
    });
  }catch(err){
    console.log("# alertSuccess Error ---> ",err);
  }finally{
    return r;
  }
};

/***
 * 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const alertError = async (ctx, msg, code=999) => {
  let r = null;
  try{
    r = await ctx.msgBoxOk( msg, {
      title: `[${code}] Error ` ,
      headerBgVariant: 'danger',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'danger',
      centered: true,
      size: 'sm'
    });
  }catch(err){
    console.log("# alertError Error ---> ",err);
  }finally{
    return r;
  }
};

/***
 * 확인 메시지 박스 출력
 * @param ctx
 * @param msg
 * @param callback
 */
export const alertConfirm = async (ctx, msg, title=null) => {
  let r = null;
  try{
    r = await ctx.msgBoxConfirm( msg, {
      title: (!title)?'[확인] Confirm':title,
      buttonSize: 'sm',
      headerBgVariant: 'warning',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      okVariant: 'danger',
      centered: true, size: 'sm'
    });
  }catch(err){
    console.log("# alertError Error ---> ",err);
  }finally{
    return r;
  }
};


/**
 * Modal confirm with callback
 * @param ctx : this.$bvModal
 * @param msg : {title},{text},{level}
 * @param callback
 */
export const confirmModal = (ctx, msg, callback) => {
  let variant = 'success';
  if( msg.level ){
    switch(msg.level){
      case 'info': variant = 'info'; break;
      case 'warn': variant = 'warning'; break;
      case 'error': variant = 'danger'; break;
      case 'danger': variant = 'danger'; break;
      case 'primary': variant = 'primary'; break;
      case 'normal': variant = 'info'; break;
      default: variant= 'success'; break;
    }
  }
  if( !msg.title ) msg['title'] = '[확인] Please Confirm';

  ctx.msgBoxConfirm( msg.text, {
    title: msg.title,
    buttonSize: 'sm',
    headerBgVariant: variant,
    noCloseOnBackdrop: true,
    noCloseOnEsc: true,
    okVariant: 'primary',
    centered: true, size: 'sm'
  }).then((value)=>{
    callback( value );
  }).catch(err=>{
    console.log( 'alertBox---------->'+ err.message );
  });

};

/**
 * Modal toast with callback
 * @param ctx : this.$bvModal
 * @param msg : {title},{text},{level}
 * @param callback
 */
export const toastMsg = (ctx, msg, callback=null) => {
  let variant = 'success';
  if( msg.level ){
    switch(msg.level){
      case 'info': variant = 'primary'; break;
      case 'warn': variant = 'warning'; break;
      case 'err': variant = 'danger'; break;
      case 'danger': variant = 'danger'; break;
      case 'norm': variant = 'info'; break;
      default: variant= 'success'; break;
    }
  }

  if( !msg.title ) msg['title'] = '[정보] Information ';

  ctx.toast( msg.text, {
    title: msg.title,
    variant: variant
  })

};

/**
 * Modal toast with async promise
 * @param ctx : this.$bvToast
 * @param msg : String
 * @param title: String
 */
export const toastSync = async (ctx, msg, type='danger',  title=null) => {
  if( !title ) title = '[정보] Information ';
  try{
    await ctx.toast( msg, {
      title: title,
      variant: type,
      autoHideDelay: 3000,
      appendToast: true
    })
  }catch(e){

  }
};

export const getNotice = (msg) => {
  let notice = {title: '', text : '', level: ''};
  try{

    if( msg.code==='200'){
      notice = {title: `[${msg.code}] 성공`, text : '저장 되었습니다.', level: 'info'};
    }else if( msg.code==='9100'){
      notice.level = 'err';
      notice.title = `[${msg.code}] 실패: ${msg.title}`;
      notice.text = '코드가 중복 되거나 이미 사용중입니다.\n생성 할 수 없습니다';
    }else{
      notice.level = 'err';
      notice.title = `[${msg.code}] 실패: `;
      notice.text =  msg.title
    }
    return notice;
  }catch(err){
    return {title: 'ERROR', text : err.message, level: 'warn'}
  }
};

export const qmcToName = (qmc) => {
  let map = {'dlp-chr':'Christie', 'dlp-bar':'Barco', 'sms-gdc' : 'GDC', 'sms-dol' : 'Dolby'};
  let name = map[qmc];
  if(name == null) return 'Unknown';
  return name;
};


/**
 * checkBizNo
 * @param bisNo
 * @returns {boolean}
 */
export const  checkBisNo = (bisNo) => {

  if(!bisNo) return false;
  // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
  bisNo = (bisNo+'').match(/\d{1}/g);

  if( !bisNo ) return false;

  if ( bisNo.length != 10) return false;

  // 합 / 체크키
  let sum = 0, key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

  for (let i = 0 ; i < 9 ; i++) { sum += (key[i] * Number(bisNo[i])); }
  let chkSum = 0;
  chkSum = Math.floor(key[8] * Number(bisNo[8]) / 10);
  sum +=chkSum;

  let reminder = (10 - (sum % 10)) % 10;
  if(reminder==Number(bisNo[9])) return true;

  return false;

};

/**
 * 연락처 하이픈(-) 자동입력
 * @param phoneNumber
 * @returns {String}
 */
export const getMask = (phoneNumber) => {
  if(!phoneNumber) return phoneNumber
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '')

  let res = ''
  if(phoneNumber.length < 3) {
      res = phoneNumber
  } else {
    if(phoneNumber.substr(0, 2) =='02') {
      if(phoneNumber.length <= 5) {//02-123-5678
          res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3)
      }
      else if(phoneNumber.length > 5 && phoneNumber.length <= 9) {//02-123-5678
          res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 3) + '-' + phoneNumber.substr(5)
      }
      else if(phoneNumber.length > 9) {//02-1234-5678
          res = phoneNumber.substr(0, 2) + '-' + phoneNumber.substr(2, 4) + '-' + phoneNumber.substr(6)
      }
    } else {
      if(phoneNumber.length < 8) {
          res = phoneNumber
      }
      else if(phoneNumber.length == 8)
      {
          res = phoneNumber.substr(0, 4) + '-' + phoneNumber.substr(4)
      }
      else if(phoneNumber.length == 9)
      {
          res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      }
      else if(phoneNumber.length == 10)
      {
          res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6)
      }
      else if(phoneNumber.length > 10) { //010-1234-5678
          res = phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 4) + '-' + phoneNumber.substr(7)
      }
    }
  }
  return res;
};


/**
 * List 데이터 페이징 처리
 * @param {List} r 페이징 처리할 list 데이터
 * @param {number} currentPage 현재 선택한 페이지 번호
 * @param {number} perPage 페이지당 출력할 개수
 */
export const paging = (r, currentPage, perPage) => {
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const rs = r.slice(startIndex, endIndex);
  return rs;
};


/**
 * UTC Date를 KOR 시간으로 변환
 * @param {Date} utcDate UTC형식의 Date 데이터
 * @param {String} formater defualt: "YYYY. MM. DD. HH:mm"
 * @returns {String} String
 */
export const momentDate = (utcDate, formater = "YYYY. MM. DD. HH:mm") => {
  const momentKor = require("moment");
  require('moment/locale/ko');

  return momentKor.utc(utcDate).local().locale('ko').format(formater);
};


/**
 * Date 시간을 UTC 형식으로 변환 (Datepicker)
 * @description Date의 한국 시간 형식에서 UTC+9로 변환
 * @param date {Date}
 */
export const dateToUtc = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

/**
 * UTC 시간을 Date 형식으로 변환 (Datepicker)
 * @description UTC+9 시간에서 9시간을 뺀 후 Date 한국시간(GMT+9)으로 변환
 * @param date {String}
 */
export const utcToDate = (date) => {
  return new Date( moment(date).subtract(9, 'hours').format("YYYY-MM-DD HH:mm:ss") );
};


export const isAuthLogin = () => {
  if (!$H_isAuthed) {
    this.$router.push( {path: '/service/login'} );
  }
}