import 'core-js/stable'
import 'babel-polyfill'; /* added for sync await function*/
import 'babel-core'; /* added for sync await function */
import Vue from 'vue'
import App from './App'
import router from './router'
import VueCookies from "vue-cookies";
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueGlobalVariable from 'vue-global-var'
import store from './store/index';
import VueExcelEditor from 'vue-excel-editor';
import VueEditableGrid from 'vue-editable-grid';
import VueFormulate from '@braid/vue-formulate';
import Spinner from './xcost/views/components/Spinner.vue';
// import "@babel/polyfill";
import Particles from "particles.vue";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import ko from "vee-validate/dist/locale/ko";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("ko", ko);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.component("Spinner", Spinner);

/////////////////////////////////////////////////////////////////
// for electron build ///////////////////////////////////////////
/*
import {
  app, ipcMain
} from 'electron';

let mainWindow, contents;
const gotTheLock = app.requestSingleInstanceLock()

if (!gotTheLock) {
  app.quit();
  app.exit();
} else {


  app.on('second-instance', (event, commandLine, workingDirectory) => {
    if (mainWindow) {
      if (mainWindow.isMinimized()) {
        mainWindow.restore();
      } else if (!win.isVisible()) {
        mainWindow.show();
      }
      mainWindow.focus();
    }
  });

  //처음 실행시

  app.on('ready', function() {
    mainWindow = new BrowserWindow({
      height: 563,
      width: 1000,
      useContentSize: true,
      webPreferences: {
        nodeIntegration: true,
        webSecurity: false
      }
    });
    contents = mainWindow.webContents;
    contents.openDevTools();

    ipcMain.on("update-check", (event,arg)=>{
      contents.send("updater-message", {msg:"ok"});
    });
  });

}
////////////////////////////////////////////////////////////////
*/
// import io from 'socket.io-client';
const protocol = window.location.protocol;
const host = window.location.hostname;
let port = (window.location.port)? ":"+window.location.port:'';
const wlPort = ":" + window.location.port;

if(protocol==="https:"){
  port = ":443"
}else{
  port = (wlPort===':8888'|| wlPort===':8080')? ":8080":"";
}

const webUrl = `${protocol}//${host}${wlPort}`;
const hostUrl = `${protocol}//${host}${port}`;
const socketUrl = `${protocol}//${host}${port}`;
console.log( "########## webUrl ------------------------> ", webUrl);
console.log( "########## hostUrl ------------------------> ", hostUrl);

// const socket = io( hostUrl,
//     {
//       reconnection: true,
//       reconnectionAttempts:10,
//       reconnectionDelay: 3000
//     });
// Vue.prototype.$socket = socket;

Vue.use( VueCookies );
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueFormulate);
Vue.use(Particles);

const excelEditorLocalizedLabelObj = {
  footerLeft: (top, bottom, total) => `레코드 ${top} ~ ${bottom} 개 / 총 ${total} 개`,
  first: '처음',
  previous: '이전',
  next: '다음',
  last: '끝',
  footerRight: {
    selected: '선택:',
    filtered: '필터:',
    loaded: '로드:'
  },
  processing: '진행중',
  tableSetting: 'Table Setting',
  exportExcel: '엑셀 추출',
  importExcel: '엑셀 업로드',
  back: '뒤로',
  reset: '리셋',
  sortingAndFiltering: '정렬/필터',
  sortAscending: '내림차순',
  sortDescending: '오름차순',
  near: '≒ 유사',
  exactMatch: '= 일치',
  notMatch: '≠ 불일치',
  greaterThan: '&gt; 초과',
  greaterThanOrEqualTo: '≥ 이상',
  lessThan: '&lt; 미만',
  lessThanOrEqualTo: '≤ 이하',
  regularExpression: '~ 정규식',
  customFilter: '사용자필터',
  listFirstNValuesOnly: n => `List first ${n} values only`,
  apply: '적용',
  noRecordIsRead: '레코드없음',
  readonlyColumnDetected: '읽기전용 컬럼',
  columnHasValidationError: (name, err) => `컬럼 ${name}값 검증에러: ${err}`,
  noMatchedColumnName: '일치하는컬럼 없음',
  invalidInputValue: '입력값 오류',
  missingKeyColumn: '키컬럼 없음'
};
Vue.use(VueExcelEditor);
Vue.use(VueEditableGrid);

Vue.use(VueGlobalVariable,{
  globals: {
    $webUrl: webUrl,
    $user: {
      user_no    : null,
      user_id    : null,
      name       : null,
      dept_name  : null,
      role       : null,
      email      : null,
      phone_no   : null,
      mobile_no  : null,
      accessToken :null,
    },
    $baseURL: hostUrl,
    $reqTimeout: 3000,
    $isAuthed: false,
    $role: null,
    $adjYear : null,
    $adjMonth : null,
    $adjDay: null,
    $searchField: null,
    $searchWord: null,
    $localizedLabel: excelEditorLocalizedLabelObj,

    $H_user: {
      userNo      : null,
      userId      : null,
      name        : null,
      role        : null,
      email       : null,
      hpNo        : null,
      regDts      : null,
      accessToken : null,
    },
    $H_isAuthed   : false,
    $imgUrl       : `${hostUrl}/images/`,
    $xcostDownload: `${webUrl}/files/xcostsetup.msi`
  }
});
// var newURL = window.location.protocol + "//" + window.location.host;


new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
});
