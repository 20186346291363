import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import adminStore from './modules/adminStore';
import userStore from './modules/userStore';

export default new Vuex.Store({
  modules: {
    adminStore,
    userStore,
  }
});