import { main } from '@popperjs/core';
import Vue from 'vue'
import Router from 'vue-router'


/////////////////////////////////////////////////
// add code for Heimdall
/////////////////////////////////////////////////
const Login = () => import('../xcost/Login');
// Containers
const TheContainer = () => import('../containers/TheContainer');

// Main Homepage
const MainContainer = () => import('../xcost/home/MainContainer');
const Main = () => import('../xcost/home/Main');
const Store = () => import('../xcost/home/Store');
const ServiceContainer = () => import('../xcost/home/ServiceContainer');
const ServiceLogin = () => import('../xcost/home/Login');
const OrderList = () => import('../xcost/home/OrderList');
const Introduction = () => import('../xcost/home/Introduction');
const SpecShop = () => import('../xcost/home/SpecShop');
const SpecShopCreate = () => import('../xcost/home/SpecShopCreate');
const AccountManager = () => import('../xcost/home/AccountManager');

// XCOST Admin views
const Dashboard = () => import('../xcost/views/Dashboard');
const UserInfo = () => import('../xcost/views/UserInfo');
const Credit = () => import('../xcost/views/Credit');
const NotFound = () => import('../xcost/views/NotFound');

// 주문 페이지
// const UserOrder = () => import('../xcost/views/user-order/OrderForm');
const OrderWithPayment = () => import('../xcost/views/user-order/OrderForm');
const UserOrderOnlyCash = () => import('../xcost/views/user-order/OrderFormOnlyCash');

const TrialOrder = () => import('../xcost/views/user-order/TrialOrderForm');
const MultiOrder = () => import('../xcost/views/subscription/MultiOrder');
const MultiOrderDetail = () => import('../xcost/views/subscription/MultiOrderDetail');
const UpgradeOrder = () => import('../xcost/views/subscription/UpgradeOrder');

const OrderFormWithPayment = () => import('../xcost/views/user-order/OrderFormWithPayment');
const XcostPremiumSubscription = () => import('../xcost/views/premium/SubscriptionForm');
const OrderDetail = () => import('../xcost/views/user-order/OrderDetail');

const MemberFind = () => import('../xcost/views/member/MemberFind.vue');
const MemberInfoUpdate = () => import('../xcost/views/member/MemberInfoUpdate.vue');
const MemberPasswordVerify = () => import('../xcost/views/member/MemberPasswordVerify');
const CouponUse = () => import('../xcost/home/coupon/CouponUse');

// 회원가입 폼
const MemberRegister = () => import('../xcost/views/member/MemberRegister.vue');
/////////////////////////////////

// 결제관리
const PaymentInfo = () => import('../xcost/views/payment/PaymentInfo');
const PaymentSales = () => import('../xcost/views/payment/PaymentSales');

// Product 관리
const ProductInfo = () => import('../xcost/views/product/ProductInfo');
const ProductKey = () => import('../xcost/views/product/ProductKey');
const ProductToken = () => import('../xcost/views/product/ProductToken');
const ProductCoupon = () => import('../xcost/views/product/ProductCoupon');

//판매 관리
const Master = () => import('../xcost/views/sales/Master');
const SalesOrder = () => import('../xcost/views/sales/SalesOrder');
const ProductOrder = () => import('../xcost/views/sales/ProductOrder');
const Upload = () => import('../xcost/views/sales/Upload');
const Tax = () => import('../xcost/views/sales/Tax');


// Setting - 설정
const Users = () => import('../xcost/views/setting/Users');

// Member - 회원
const WebMemberInfo = () => import('../xcost/views/member/WebMemberInfo');
const XcostMemberInfo = () => import('../xcost/views/member/XcostMemberInfo');
const PasswordReset = () => import('../xcost/views/member/PasswordReset');

// quote - 견적서
const QuoteInfo = () => import('../xcost/views/quote/QuoteInfo');
const QuoteInfoInput = () => import('../xcost/views/quote/QuoteInfoInput');

// AddressBook - 주소록
const AddressBookMain = () => import('../xcost/views/addressBook/AddressBookMain');

const titleFull = '(주)하우코스트 건설계약연구소';
const titleCompany = '(주)하우코스트';
const titleOffice = '하우코스트 오피스';


const requireAuth = () => (from, to, next) => {
  console.log( "requireAuth()----------> $isAuthed: ", $isAuthed);
  if ( $isAuthed ) return next();
  next('/admin-x/login');
};


Vue.use(Router);

const routes = [
  {
    path: '/service',
    redirect: '/',
    name: 'ServiceContainer',
    component: ServiceContainer,
    children: [
      {
        path: 'register',
        name: 'MemberRegister',
        component: MemberRegister,
        meta: { title: `${titleCompany} 회원가입` },
      },
      {
        path: 'login',
        name: 'ServiceLogin',
        component: ServiceLogin,
        meta: { title: `${titleCompany} 로그인` },
      },
      {
        path: 'member-password-verify',
        name: 'MemberPasswordVerify',
        component: MemberPasswordVerify,
        meta: { title: `${titleCompany} 정보변경`, requiresAuth: true },
      },
      {
        path: 'coupon-use',
        name: 'CouponUse',
        component: CouponUse,
        meta: { title: `${titleCompany} 쿠폰함`, requiresAuth: true },
      },
      {
        path: 'account-manager',
        name: 'AccountManager',
        component: AccountManager,
        meta: { title: `${titleCompany} 계정관리` },
      },
    ]
  },
  {
    path: '/',
    redirect: '/main',
    name: 'MainContainer',
    component: MainContainer,
    children: [
      {
        path: 'main',
        name: 'Main',
        component: Main
      },
      {
        path: 'store',
        name: 'Store',
        component: Store,
        meta: { title: `${titleCompany} 솔루션 스토어` },
      },
      {
        path: 'order-list',
        name: 'OrderList',
        component: OrderList,
        meta: { title: `${titleCompany} 주문목록`, requiresAuth: true },
      },
      {
        path: 'introduction',
        name: 'Introduction',
        component: Introduction,
        meta: { title: `${titleCompany} 솔루션 소개` },
      },
      {
        path: 'spec-shop',
        name: 'SpecShop',
        component: SpecShop,
        meta: { title: `${titleCompany} 스펙샵` },
      },
      {
        path: 'spec-shop-create',
        name: 'SpecShopCreate',
        component: SpecShopCreate,
        meta: { title: `${titleCompany} 스펙샵 등록`, requiresAuth: true },
      },
      
    ]
  },
  {
    path: '/admin-x',
    redirect: '/admin-x/dashboard',
    name: 'Home',
    beforeEnter: requireAuth(),
    component: TheContainer,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { title: `${titleOffice} 통계` },
      },
      {
        path: 'user-info',
        name: 'UserInfo',
        component: UserInfo,
        meta: { title: `${titleOffice} 사용자정보` },
      },
      {
        path: 'product',
        redirect: '/admin-x/product/key',
        meta: {
          label: '제품정보',
        },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {path: 'info', name: '제품정보', component: ProductInfo, meta: { title: `${titleOffice} 제품정보` },},
          {path: 'key', name: '제품키관리', component: ProductKey, meta: { title: `${titleOffice} 제품키관리` },},
          {path: 'token',  name: '인증토큰관리', component: ProductToken, meta: { title: `${titleOffice} 인증토큰관리` },},
          {path: 'coupon',  name: '쿠폰관리', component: ProductCoupon, meta: { title: `${titleOffice} 쿠폰관리` },},
        ]
      },
      {
        path: 'sales',
        redirect: '/admin-x/sales/master',
        meta: {
          label: '판매관리'
        },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {path: 'master',  name: '패키지상품관리', component: Master, meta: { title: `${titleOffice} 상품마스터관리` },},
          {path: 'order',   name: '상품주문', component: SalesOrder, meta: { title: `${titleOffice} 상품주문관리` },},
          {path: 'product', name: '제품주문', component: ProductOrder, meta: { title: `${titleOffice} 제품주문관리` },},
          {path: 'upload',  name: '주문업로드', component: Upload, meta: { title: `${titleOffice} 주문업로드` },},
          {path: 'tax', name: '세금계산서', component: Tax, meta: { title: `${titleOffice} 세금계산서` },},
        ]
      },
      {
        path: 'payment',
        redirect: '/admin-x/payment/info',
        meta: {
          label: '결제관리'
        },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {path: 'info',  name: '카드결제(구)', component: PaymentInfo, meta: { title: `${titleOffice} 카드결제(구)` },},
          {path: 'sales',  name: '카드결제내역', component: PaymentSales, meta: { title: `${titleOffice} 카드결제내역` },},
        ]
      },
      {
        path: 'member',
        redirect: '/admin-x/member/xcost',
        meta: {
          label: '회원정보'
        },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {path: 'xcost',  name: 'Xcost회원정보', component: XcostMemberInfo, meta: { title: `${titleOffice} Xcost회원정보` },},
          {path: 'web',  name: '커뮤니티 회원정보', component: WebMemberInfo, meta: { title: `${titleOffice} 커뮤니티 회원정보` },},
        ]
      },
      {
        path: 'setting',
        redirect: '/admin-x/setting/users',
        meta: {
          label: 'Setting'
        },
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {path: 'users',  name: '오피스 사용자 관리', component: Users, meta: { title: `${titleOffice} 오피스 사용자관리` },},
        ]
      },
    ]
  },
  {
    path: '/user-order/202106',
    name: 'UserOrder202106',
    props: true,
    component: UserOrderOnlyCash,
  },
  {
    path: '/user-order/form',
    name: 'UserOrderWithPayment',
    props: true,
    component: OrderFormWithPayment,
  },
  {
    path: '/premium/subscription/:prdCode?',
    name: 'XcostPremiumSubscription',
    props: true,
    component: XcostPremiumSubscription,
  },
  {
    path: '/subscription/multi-order/:salesCode',
    name: 'MultiOrder',
    props: true,
    component: MultiOrder,
  },
  {
    path: '/subscription/multi-order/detail/:orderToken',
    name: 'MultiOrderDetail',
    props: true,
    component: MultiOrderDetail,
    meta: { title: `${titleCompany} 구독결제` },
  },
  {
    path: '/subscription/upgrade-order/:salesCode',
    name: 'UpgradeOrder',
    props: true,
    component: UpgradeOrder,
  },
  {
    path: '/order/:prdCode',
    name: 'OrderForm',
    props: true,
    component: OrderWithPayment,
  },
  {
    path: '/order-detail/:orderToken',
    name: 'OrderDetail',
    props: true,
    component: OrderDetail,
  },
  {
    path: '/trial-order',
    name: 'TrialOrder',
    props: true,
    component: TrialOrder,
  },
  {
    path: '/member-pwd-reset/:memberToken',
    name: 'PasswordReset',
    props: true,
    component: PasswordReset,
    meta: { title: `${titleCompany} 회원 비밀번호 초기회` },
  },
  {
    path: '/member-find',
    name: 'MemberFind',
    props: true,
    component: MemberFind,
    meta: { title: `${titleCompany} 회원 계정찾기` },
  },
  {
    path: '/member-info-update/:memberToken',
    name: 'MemberInfoUpdate',
    props: true,
    component: MemberInfoUpdate,
    meta: { title: `${titleCompany} 회원 정보수정` },
  },
  {
    path: '/member-register',
    name: 'MemberRegister',
    props: true,
    component: MemberRegister,
    meta: { title: `${titleCompany} 회원가입` },
  },
  {
    path: '/quote-info',
    name: 'QuoteInfo',
    props: true,
    component: QuoteInfo,
    meta: { title: `${titleCompany} 견적서 정보` },
  },
  {
    path: '/quote-info-input/:uno',
    name: 'QuoteInfoInput',
    props: true,
    component: QuoteInfoInput,
    meta: { title: `${titleCompany} 견적서 입력` },
  },
  {
    path: '/address-book-main',
    name: 'AddressBookMain',
    props: true,
    component: AddressBookMain,
    meta: { title: `${titleCompany} 주소록` },
  },
  {
    path: '/admin-x/login',
    name: 'Login',
    component: Login,
    meta: { title: `${titleOffice} 로그인` },
  },
  {
    path: '/credit',
    name: 'Credit',
    component: Credit
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]


const router = new Router({
  // mode: 'history', // https://router.vuejs.org/api/mode
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '(주)하우코스트 건설계약연구소';

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated  = !!localStorage.getItem('accessToken'); // 토큰으로 로그인 여부 확인

  if (requiresAuth && !isAuthenticated ) {
    next({
      path: '/service/login',
      query: { redirect: to.fullPath } // 이동하려는 경로를 쿼리로 전달
    });
  } else {
    next();
  }
});

export default router;
