<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

<!-- 공통 적용 스크립트 , 모든 페이지에 노출되도록 설치. 단 전환페이지 설정값보다 항상 하단에 위치해야함 -->
<script type="text/javascript" src="//wcs.naver.net/wcslog.js"> </script>
<script type="text/javascript">
  if (!wcs_add) var wcs_add={};
  wcs_add["wa"] = "s_25721de17caa";
  if (!_nasa) var _nasa={};
  if(window.wcs){
    wcs.inflow();
    wcs_do();
  }
</script>
